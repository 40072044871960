.hero {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-left: -12rem;
    // align-items: center;

    &__text {
        grid-column: 1/2;
        justify-self: center;
        align-self: center;

        .hero__heading {
            font-size: 7rem;
            font-family: $jelani;
            line-height: 6rem;
            font-weight: lighter;
            margin-bottom: 2rem;
        }
    }
    
    &__img {
        grid-column: 2/3;
        position: relative;
        align-self: center;
        margin-top: -45rem;

        img {
            position: absolute;
            border-radius: 2rem;
        }
        
        &--1 {
            left: 0;
            top: 0;
        }
        &--2 {
            left: 33rem;
            top: -1rem;
        }
        &--3 {
            left: 33rem;
            top: 13rem;
        }
        &--4 {
            left: 46rem;
            top: 9rem;
        }
        &--5 {
            left: 33rem;
            top: 27rem;
        }
    }
}
